import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from 'react-bootstrap';

// Ui-kit
import Button from 'ui-kit/button/button';

// Components
import AddFamilyMemberDetails from '../member-details';
// Styles
import './add-family-member-confirm-details-form.style.scss';

interface MemberToAdd {
    firstName: string;
    lastName: string;
    age?: string;
    dateOfBirth?: string;
    prescriptionNumber: string;
    insuranceID: string | null;
    accountType: number;
}

interface AddFamilyMemberConfirmDetailsFormProps {
    dependentData: MemberToAdd;
    isBusy?: boolean;
    className?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

const AddFamilyMemberConfirmDetailsForm = ({
    dependentData,
    isBusy,
    className,
    onSubmit,
    onCancel
}: AddFamilyMemberConfirmDetailsFormProps) => {
    const { t } = useTranslation();
    const containerClasses = classNames('add-family-member-confirm-details-form', className);

    return (
        <Container>
            <div className={containerClasses}>
                <div className="add-family-member-confirm-details-form__details-container">
                    <AddFamilyMemberDetails
                        name={`${dependentData.firstName} ${dependentData.lastName}`}
                        age={dependentData.age}
                        accountType={dependentData.accountType}
                        prescriptionNumber={dependentData.prescriptionNumber}
                        dob={dependentData.dateOfBirth}
                        insuranceID={dependentData.insuranceID || ''}
                    />
                </div>
                <div className="add-family-member-confirm-details-form__btn-container">
                    <Button
                        async
                        className="btn-confirm-add-minor"
                        disabled={isBusy}
                        isBusy={isBusy}
                        label={t('components.addFamilyMember.confirmDetails.actions.submit')}
                        onClick={() => onSubmit()}
                        type={'button'}
                    />
                    <Button
                        className="btn-cancel-add-minor"
                        disabled={isBusy}
                        label={t('components.addFamilyMember.confirmDetails.actions.cancel')}
                        onClick={() => onCancel()}
                        variant={'text'}
                        type={'button'}
                    />
                </div>
            </div>
        </Container>
    );
};

export default AddFamilyMemberConfirmDetailsForm;
