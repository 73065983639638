import { ReactElement, useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';

// Hooks
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import AddFamilyMemberConfirmDetailsForm from 'display-components/add-family-member/confirm-details-form';
import { BirdiModalHeaderDanger, BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import {
    FamilyAccountSuccessModalContent,
    FamilyAccountAddMemberGenericErrorModalContent,
    FamilyAccountAddMemberAlreadyMemberErrorModalContent
} from 'display-components/add-family-member/modals';

// Interfaces and types
import { FamilyAccountAddDependentResponse } from 'types/family-management';

// States
import { accountProfileSelector } from 'state/account/account.selectors';
import { familyProfileDependentToAddSelector } from 'state/family-profile/family-profile.selectors';
import { familyProfileAddDependentRoutine } from 'state/family-profile/family-profile.routines';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

// Styles
import './add-a-pet-confirm-flow.style.scss';

// Interface for Dependent Treated Data.
interface DependentDataTreated {
    firstName: string;
    lastName: string;
    age?: string;
    dateOfBirth?: string;
    prescriptionNumber: string;
    insuranceID: string;
    accountType: number;
    email?: string | null;
    phone?: string | null;
}

// Main component
const AddAPetConfirmFlow = (): ReactElement => {
    // General
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultBackUrl = '/secure/profile/family-account/add-a-pet';
    const successRedirectUrl = '/secure/profile/family-account/';

    // Selectors
    const dependentToAddSelector = useSelector(familyProfileDependentToAddSelector);
    const profileObject = useSelector(accountProfileSelector);

    // States
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dependentData, setDependentData] = useState<DependentDataTreated | null>(null);
    const [epostPatientNum, setEpostPatientNum] = useState<string>('');

    // Get data from selector and treat it.
    useEffect(() => {
        if (!dependentToAddSelector || !dependentToAddSelector.FirstName) {
            navigate(defaultBackUrl);
        }

        if (dependentToAddSelector) {
            setIsLoading(false);
            setDependentData({
                firstName: dependentToAddSelector?.FirstName,
                lastName: dependentToAddSelector?.LastName,
                prescriptionNumber: dependentToAddSelector?.PrescriptionNumber,
                insuranceID: dependentToAddSelector?.InsuranceMemberId,
                accountType: dependentToAddSelector?.AccountType
            });
        }
    }, [dependentToAddSelector]);

    // Get profile ePostPatientNum.
    useEffect(() => {
        if (profileObject && profileObject.epostPatientNum) {
            setEpostPatientNum(profileObject.epostPatientNum);
        }
    }, [profileObject]);

    // Show success modal after finish adding the dependent Minor < 13.
    const showSuccessModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'primary',
                    size: 'lg',
                    onClose: () => {
                        setIsBusy(false);
                        navigate(successRedirectUrl);
                        dispatch(closeModal({}));
                    },
                    headerContent: (
                        <BirdiModalHeaderWithBackgroundColor
                            headerText={t('pages.profile.addFamilyMember.successModal')}
                            type="info"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountSuccessModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                navigate(successRedirectUrl);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Show generic error modal for any other failure than member already added to another account.
    const showGenericErrorModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberGenericErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Show error modal when use is already add to another account.
    const showDependentAlreadyAddedErrorModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberAlreadyMemberErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Add minor after clicking on submit button.
    const handleOnSubmit = (contactMethod?: string) => {
        if (dependentData) {
            setIsBusy(true);
            dispatch(
                familyProfileAddDependentRoutine.trigger({
                    dependentData: {
                        FirstName: dependentData.firstName,
                        LastName: dependentData.lastName,
                        DateOfBirth: dependentData.dateOfBirth,
                        PrescriptionNumber: dependentData.prescriptionNumber,
                        EPostPatientNumber: epostPatientNum,
                        AccountType: 'Pet'
                    },
                    onSuccess: (response: FamilyAccountAddDependentResponse) => {
                        showSuccessModal(dependentData.firstName);
                    },
                    onFailure: (errors: FamilyAccountAddDependentResponse['Errors']) => {
                        if (errors[0].search('has already been added') >= 0) {
                            showDependentAlreadyAddedErrorModal(dependentData.firstName);
                        } else {
                            showGenericErrorModal(dependentData.firstName);
                        }
                    }
                })
            );
        }
    };

    // On cancel, go back to dependent listing page.
    const handleOnCancel = () => {
        navigate(defaultBackUrl, { state: dependentToAddSelector ?? undefined });
    };

    return (
        <div className="add-minor-confirm-or-send-invitation-flow__container">
            {isLoading ? (
                <div className="loading">{t('pages.profile.addFamilyMember.loadingText')}</div>
            ) : (
                <AddFamilyMemberConfirmDetailsForm
                    dependentData={dependentData as DependentDataTreated}
                    isBusy={isBusy}
                    onSubmit={handleOnSubmit}
                    onCancel={handleOnCancel}
                />
            )}
        </div>
    );
};
export default AddAPetConfirmFlow;
